import { FileAttachment } from '@shared/models';

const isPdf = (file: FileAttachment): boolean => {
    if (!file) return false;
    const fileType = file.downloadURL.split('.').at(-1);
    return fileType === 'pdf'; // TODO: refactor this .. needs proper mimetype
};

const isImage = (file: FileAttachment): boolean => {
    const imageTypes: string[] = ['png', 'jpg', 'jpeg', 'tiff', 'webp'];
    if (!file) return false;
    const fileType = file.downloadURL.split('.').at(-1) || '';
    return imageTypes.includes(fileType);
};

export { isPdf, isImage };
