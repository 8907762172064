import { ApplicationModel } from '@modules/subsidy/models';
import { EmployeeVoucherModel } from '@modules/benefit/models';

class EmployeeWalletModel {
    subsidies: ApplicationModel[] = [];
    vouchers: IVoucherYearGroup = {} as IVoucherYearGroup;
    benefits?: string[] = [];
}

interface IVoucherYearGroup {
    [key: string]: IVoucherMonthGroup[];
}

interface IVoucherMonthGroup {
    month: number;
    vouchers: EmployeeVoucherModel[];
}

interface IVoucherDisplay {
    year: string;
    vouchers: IVoucherMonthGroup[];
}

export { EmployeeWalletModel, IVoucherYearGroup, IVoucherMonthGroup, IVoucherDisplay };
