class PayrollDesignationsModel {
    name = '';
    payrollAccountNumbers: PayrollAccountNumbers = new PayrollAccountNumbers();
}

class PayrollAccountNumbers {
    fringeBenefits: string | number = '';
    publicTransport: string | number = '';
    internetSubsidy: string | number = '';
    childCareSubsidy: string | number = '';
    cashSalary?: string | number;
}

export { PayrollDesignationsModel, PayrollAccountNumbers };
