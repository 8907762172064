import { DateTime, Info, UnitLength } from 'luxon';

const isLastMonth = (date: number): boolean => {
    const currentMonth = DateTime.fromMillis(new Date().getTime()).month;
    const compare = DateTime.fromMillis(date).month;
    return compare < currentMonth;
};

const isCurrentMonth = (date: number): boolean => {
    const currentMonth = DateTime.fromMillis(new Date().getTime()).month;
    const compare = DateTime.fromMillis(date).month;
    return compare === currentMonth;
};

const getMonthList = (locale: string = 'en', length: UnitLength = 'short'): string[] => {
    return Info.months(length, { locale });
};

const getCurrentMonthAndYear = (): string => {
    return DateTime.now().toFormat('MMMM yyyy');
};

const getCurrentMonthNumber = (): number => {
    return DateTime.now().month;
};

const getCurrentYear = (): number => {
    return DateTime.now().year;
};

export { isLastMonth, isCurrentMonth, getMonthList, getCurrentMonthAndYear, getCurrentMonthNumber, getCurrentYear };
