import { BaseApiModel } from '@shared/models/base-api.model';

class FileAttachment extends BaseApiModel {
    filename = '';
    name = '';
    storageName = '';
    downloadURL = '';
    downloadPath = '';
    mimeType: any;
    size?: number;
    downloadProgress?: number;

    setDownloadProgress?(progress: number) {
        this.downloadProgress = progress;
    }
}

export { FileAttachment };
